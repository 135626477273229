import "./App.css";
import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
    width: "100vw",
    height: "100vh",
};

function MyComponent() {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDqb2XlJJbdHDsu8mDDz4oAAMdevM011bI",
    });

    const [map, setMap] = React.useState(null);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: 25.196758350555093, lng: 55.30437058546811 }}
            zoom={11}
            heading={-45}
            options={{
                mapId: "36ac671db067bd86",
                disableDefaultUI: true,
                restriction: {
                    strictBounds: false,
                },
            }}
        >
            <></>
        </GoogleMap>
    ) : (
        <></>
    );
}

export default React.memo(MyComponent);
